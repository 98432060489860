import React from 'react'

import imgLogoNol from '../../../assets/images/logo.svg'
import iconCo from '../../../assets/images/icon-co.png'
import iconAr from '../../../assets/images/icon-ar.png'
import iconBo from '../../../assets/images/icon-bo.png'
import iconCl from '../../../assets/images/icon-cl.png'
import iconEc from '../../../assets/images/icon-ec.png'
import iconPy from '../../../assets/images/icon-py.png'
import iconPe from '../../../assets/images/icon-pe.png'
import iconPr from '../../../assets/images/icon-pr.png'
import iconDo from '../../../assets/images/icon-do.png'
import iconUy from '../../../assets/images/icon-uy.png'

import './landings-template.scss'

const LandingsTemplate = props => {
  const {
    // slug,
    subtitulo,
    fondo,
    imagenPrincipal,
    urlArgentina,
    urlBolivia,
    urlChile,
    urlColombia,
    urlParaguay,
    urlEcuador,
    urlPeru,
    urlPuertoRico,
    urlRepublicaDominicana,
    urlUruguay,
  } = props.pageContext

  return (
    <div
      className="App"
      style={{
        background: `url(${fondo[0].file.url}) center no-repeat`,
      }}
    >
      <div className="App-container">
        <section className="section-img">
          <img
            src={imagenPrincipal[0].file.url}
            alt={imagenPrincipal[0].description}
          />
        </section>
        <section className="section-content">
          <div className="container-title">
            <h1 className="title">
              Bienvenida a <img src={imgLogoNol} alt="Logo: NosotrasOnline" />
            </h1>
            <p>{subtitulo}</p>
          </div>
          <div className="card-container">
            <div className="container-countries">
              {urlColombia !== 'contenido-oculto' && (
                <a href={urlColombia}>
                  <img src={iconCo} alt={''} /> Colombia
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlArgentina !== 'contenido-oculto' && (
                <a href={urlArgentina}>
                  <img src={iconAr} alt={''} /> Argentina
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlBolivia !== 'contenido-oculto' && (
                <a href={urlBolivia}>
                  <img src={iconBo} alt={''} /> Bolivia
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlChile !== 'contenido-oculto' && (
                <a href={urlChile}>
                  <img src={iconCl} alt={''} /> Chile
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlEcuador !== 'contenido-oculto' && (
                <a href={urlEcuador}>
                  <img src={iconEc} alt={''} /> Ecuador
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlParaguay !== 'contenido-oculto' && (
                <a href={urlParaguay}>
                  <img src={iconPy} alt={''} /> Paraguay
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlPeru !== 'contenido-oculto' && (
                <a href={urlPeru}>
                  <img src={iconPe} alt={''} /> Perú
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlPuertoRico !== 'contenido-oculto' && (
                <a href={urlPuertoRico}>
                  <img src={iconPr} alt={''} /> Puerto Rico
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlRepublicaDominicana !== 'contenido-oculto' && (
                <a href={urlRepublicaDominicana}>
                  <img src={iconDo} alt={''} /> República Dominicana
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
              {urlUruguay !== 'contenido-oculto' && (
                <a href={urlUruguay}>
                  <img src={iconUy} alt={''} /> Uruguay
                  <span className="f-icon icon-down f-icon-drop"></span>
                </a>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default LandingsTemplate
